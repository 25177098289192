const PatternCategories = [
    { id: 1, name: '哺乳類' },
    { id: 2, name: '鳥類' },
    { id: 3, name: '魚類' },
    { id: 4, name: '爬虫類・両生類' },
    // { id: 5, name: 'その他' },
];

const PatternImagesData = [
    { id: 1, category_id: 1, order: 1, file_name: 'pt01.png', title: 'ネコ三毛' },
    { id: 2, category_id: 1, order: 2, file_name: 'pt02.png', title: 'ネコハチワレ' },
    { id: 3, category_id: 1, order: 3, file_name: 'pt03.png', title: 'ネコ黒' },
    { id: 4, category_id: 1, order: 4, file_name: 'pt23.png', title: 'ネコ茶トラ' },
    { id: 51, category_id: 1, order: 5, file_name: 'pt52.png', title: 'ネコサバトラ' },
    { id: 52, category_id: 1, order: 6, file_name: 'pt53.png', title: 'ネコ白' },
    { id: 5, category_id: 1, order: 7, file_name: 'pt04.png', title: 'シバイヌ' },
    { id: 6, category_id: 1, order: 8, file_name: 'pt26.png', title: 'コーギー'},
    { id: 7, category_id: 1, order: 9, file_name: 'pt27.png', title: 'ゴールデンレトリバー' },
    { id: 8, category_id: 1, order: 10, file_name: 'pt28.png', title: 'ミニチュアダックスフンド' },
    { id: 59, category_id: 1, order: 11, file_name: 'pt59.png', title: 'シェットランドシープドッグ' },
    { id: 9, category_id: 1, order: 12, file_name: 'pt06.png', title: 'ウサギ白' },
    { id: 54, category_id: 1, order: 13, file_name: 'pt54.png', title: 'ウサギ黒' },
    { id: 55, category_id: 1, order: 14, file_name: 'pt55.png', title: 'ウサギダッチ'  },
    { id: 45, category_id: 1, order: 15, file_name: 'pt45.png', title: 'ハムスターゴールデン' },
    { id: 46, category_id: 1, order: 16, file_name: 'pt46.png', title: 'ハムスタージャンガリアン' },
    { id: 60, category_id: 1, order: 17, file_name: 'pt60.png', title: 'モルモット白茶' },
    { id: 61, category_id: 1, order: 18, file_name: 'pt61.png', title: 'モルモット白茶黒' },
    { id: 10, category_id: 1, order: 19, file_name: 'pt32.png', title: 'クマ' },
    { id: 11, category_id: 1, order: 20, file_name: 'pt11.png', title: 'シロクマ' },
    { id: 12, category_id: 1, order: 21, file_name: 'pt12.png', title: 'ヒツジ' },
    { id: 13, category_id: 1, order: 22, file_name: 'pt30.png', title: '牛ホルスタイン' },
    { id: 14, category_id: 1, order: 23, file_name: 'pt31.png', title: '牛ガーンジィ' },
    { id: 15, category_id: 1, order: 24, file_name: 'pt13.png', title: 'ワオキツネザル' },
    { id: 16, category_id: 1, order: 25, file_name: 'pt14.png', title: 'ラッコ' },
    { id: 17, category_id: 1, order: 26, file_name: 'pt16.png', title: 'パンダ' },
    { id: 18, category_id: 1, order: 27, file_name: 'pt17.png', title: 'キリン' },
    { id: 19, category_id: 1, order: 28, file_name: 'pt18.png', title: 'ハリネズミ' },
    { id: 20, category_id: 1, order: 29, file_name: 'pt25.png', title: 'ブタ' },
    { id: 42, category_id: 1, order: 30, file_name: 'pt42.png', title: 'カワウソ' },
    { id: 43, category_id: 1, order: 31, file_name: 'pt43.png', title: 'キツネ' },
    { id: 44, category_id: 1, order: 32, file_name: 'pt44.png', title: 'タヌキ' },
    { id: 47, category_id: 1, order: 33, file_name: 'pt47.png', title: 'ジャガー' },
    { id: 48, category_id: 1, order: 34, file_name: 'pt48.png', title: 'トラ' },
    { id: 49, category_id: 1, order: 35, file_name: 'pt49.png', title: 'オオアリクイ' },
    { id: 50, category_id: 1, order: 36, file_name: 'pt50.png', title: 'ミナミコアリクイ' },
    { id: 51, category_id: 1, order: 37, file_name: 'pt51.png', title: 'マレーバク' },
    { id: 21, category_id: 2, order: 38, file_name: 'pt29.png', title: 'アライグマ' },
    { id: 58, category_id: 1, order: 39, file_name: 'pt58.png', title: 'オカピ' },
    { id: 22, category_id: 2, order: 40, file_name: 'pt07.png', title: 'ニワトリ' },
    { id: 23, category_id: 2, order: 41, file_name: 'pt08.png', title: 'ペンギン' },
    { id: 24, category_id: 2, order: 42, file_name: 'pt09.png', title: '桜文鳥' },
    { id: 25, category_id: 2, order: 43, file_name: 'pt10.png', title: '白文鳥' },
    { id: 35, category_id: 2, order: 44, file_name: 'pt35.png', title: 'セキセイインコ青' },
    { id: 36, category_id: 2, order: 45, file_name: 'pt36.png', title: 'セキセイインコ緑' },
    { id: 37, category_id: 2, order: 46, file_name: 'pt37.png', title: 'コザクラインコ黄' },
    { id: 38, category_id: 2, order: 47, file_name: 'pt38.png', title: 'コザクラインコ緑' },
    { id: 39, category_id: 2, order: 48, file_name: 'pt39.png', title: 'オカメインコ' },
    { id: 33, category_id: 2, order: 49, file_name: 'pt33.png', title: 'オニオオハシ' },
    { id: 34, category_id: 2, order: 50, file_name: 'pt34.png', title: 'フラミンゴ' },
    { id: 26, category_id: 2, order: 51, file_name: 'pt15.png', title: 'ハシビロコウ' },
    { id: 27, category_id: 2, order: 52, file_name: 'pt19.png', title: 'シマエナガ' },
    { id: 62, category_id: 2, order: 53, file_name: 'pt20.png', title: 'スズメ' },
    { id: 63, category_id: 2, order: 54, file_name: 'pt62.png', title: 'フクロウ' },
    { id: 28, category_id: 2, order: 55, file_name: 'pt63.png', title: 'シロフクロウ' },
    { id: 29, category_id: 3, order: 56, file_name: 'pt05.png', title: 'チンアナゴニシキアナゴ' },
    { id: 30, category_id: 3, order: 57, file_name: 'pt21.png', title: 'リュウグウノツカイ' },
    { id: 31, category_id: 4, order: 58, file_name: 'pt22.png', title: 'カエル' },
    { id: 40, category_id: 4, order: 59, file_name: 'pt40.png', title: 'アカメアマガエル' },
    { id: 32, category_id: 4, order: 60, file_name: 'pt24.png', title: 'ボールパイソン' },
    { id: 41, category_id: 4, order: 61, file_name: 'pt41.png', title: 'エメラルドツリーボア' },
    { id: 56, category_id: 4, order: 62, file_name: 'pt56.png', title: 'ボールパイソン改' },
    { id: 57, category_id: 4, order: 63, file_name: 'pt57.png', title: 'エメラルドツリーボア改' }
];

const BaseImagesData = [
    { id: 1, order: 1, file_name: 'base01.png', title: '白', omikuji: "しずかな" },
    { id: 2, order: 2, file_name: 'base02.png', title: '薄桃', omikuji: "やさしい" },
    { id: 3, order: 3, file_name: 'base17.png', title: '黄色', omikuji: "元気な" },
    { id: 4, order: 4, file_name: 'base04.png', title: '黄緑', omikuji: "親切な" },
    { id: 5, order: 5, file_name: 'base05.png', title: '水色', omikuji: "すがすがしい" },
    { id: 6, order: 6, file_name: 'base06.png', title: '薄紫', omikuji: "美しい" },
    { id: 16, order: 7, file_name: 'base16.png', title: '薄ピンク', omikuji: "楽しい" },
    { id: 7, order: 8, file_name: 'base07.png', title: '赤', omikuji: "あたたかい" },
    { id: 8, order: 9, file_name: 'base08.png', title: '濃いピンク', omikuji: "明るい" },
    { id: 9, order: 10, file_name: 'base09.png', title: '橙', omikuji: "すこやかな" },
    { id: 10, order: 11, file_name: 'base10.png', title: '緑', omikuji: "心地よい" },
    { id: 15, order: 12, file_name: 'base15.png', title: '青緑', omikuji: "純粋な" },
    { id: 11, order: 13, file_name: 'base11.png', title: '青', omikuji: "落ちついた" },
    { id: 12, order: 14, file_name: 'base12.png', title: 'グレー', omikuji: "誠実な" },
    { id: 13, order: 15, file_name: 'base13.png', title: '茶色', omikuji: "穏やかな" },
    { id: 14, order: 16, file_name: 'base14.png', title: 'こげ茶', omikuji: "頼もしい" },
    { id: 17, order: 17, file_name: 'oshiri-base-01.png', title: 'シマシマ', omikuji: "頼もしい" },
    { id: 18, order: 18, file_name: 'base-autumn-01.png', title: '夜空', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 19, order: 19, file_name: 'base-autumn-02.png', title: 'オレンジ', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 20, order: 20, file_name: 'base-autumn-03.png', title: '紫', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 21, order: 21, file_name: 'base-autumn-04.png', title: 'グレージュ', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 22, order: 22, file_name: 'base-autumn-05.png', title: 'くすみブルー', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 23, order: 23, file_name: 'base-autumn-06.png', title: 'くすみピンク', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 24, order: 24, file_name: 'base-dango01.png', title: '三色団子', omikuji: "頼もしい", event_name: 'SpringEvent' },
    { id: 25, order: 25, file_name: 'base-ichigo01.png', title: 'いちご', omikuji: "頼もしい", event_name: 'SpringEvent' },
    { id: 26, order: 26, file_name: 'summer-base-01.png', title: 'スイカ①', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 27, order: 27, file_name: 'summer-base-02.png', title: 'スイカ②', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 28, order: 28, file_name: 'summer-base-03.png', title: 'トマト', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 29, order: 29, file_name: 'summer-base-04.png', title: 'ナス', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 30, order: 30, file_name: 'base-xmas-01.png', title: 'みどり', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 31, order: 31, file_name: 'base-xmas-02.png', title: 'ベージュ', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 32, order: 32, file_name: 'base-xmas-03.png', title: '濃いベージュ', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 33, order: 33, file_name: 'base-xmas-04.png', title: 'やさしい赤', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 34, order: 34, file_name: 'base-xmas-05.png', title: '深い赤', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 35, order: 35, file_name: 'base-xmas-06.png', title: '紺', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 36, order: 36, file_name: 'base-xmas-07.png', title: 'ダイヤ', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 37, order: 37, file_name: 'base-xmas-08.png', title: 'チェック', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 38, order: 38, file_name: 'base-xmas-09.png', title: 'ピンク', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 39, order: 39, file_name: 'base-xmas-10.png', title: '水浅葱', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 40, order: 40, file_name: 'base-xmas-11.png', title: '黄金', omikuji: "頼もしい", event_name: 'XmasEvent' },

];

const BackGroundImagesData = [
    { id: 1, order: 1, file_name: 'bc01.jpg', title: '白', omikuji: "しずかで" },
    { id: 2, order: 2, file_name: 'bc02.jpg', title: '薄桃', omikuji: "やさしく" },
    { id: 3, order: 3, file_name: 'bc17.png', title: '黄色', omikuji: "元気で" },
    { id: 4, order: 4, file_name: 'bc04.jpg', title: '黄緑', omikuji: "親切で" },
    { id: 5, order: 5, file_name: 'bc05.jpg', title: '水色', omikuji: "すがすがしく" },
    { id: 6, order: 6, file_name: 'bc06.jpg', title: '薄紫', omikuji: "美しく" },
    { id: 16, order: 7, file_name: 'bc16.jpg', title: '薄ピンク', omikuji: "楽しく" },
    { id: 7, order: 8, file_name: 'bc07.jpg', title: '赤', omikuji: "あたたかく" },
    { id: 8, order: 9, file_name: 'bc08.jpg', title: '濃いピンク', omikuji: "明るく" },
    { id: 9, order: 10, file_name: 'bc09.jpg', title: '橙', omikuji: "すこやかで" },
    { id: 10, order: 11, file_name: 'bc10.jpg', title: '緑', omikuji: "心地よく" },
    { id: 15, order: 12, file_name: 'bc15.jpg', title: '青緑', omikuji: "純粋で" },
    { id: 11, order: 13, file_name: 'bc11.jpg', title: '青', omikuji: "落ちついて" },
    { id: 12, order: 14, file_name: 'bc12.jpg', title: 'グレー', omikuji: "誠実で" },
    { id: 13, order: 15, file_name: 'bc13.png', title: '茶色', omikuji: "穏やかで" },
    { id: 14, order: 16, file_name: 'bc14.png', title: 'こげ茶', omikuji: "頼もしく" },
    { id: 17, order: 17, file_name: 'bc-img01.png', title: '淡い草原', omikuji: "頼もしく" },
    { id: 18, order: 18, file_name: 'bc-img02.png', title: '草原', omikuji: "頼もしく" },
    { id: 19, order: 19, file_name: 'grd-01.png', title: 'グラデ薄ピンク薄紫', omikuji: "頼もしく" },
    { id: 20, order: 20, file_name: 'grd-02.png', title: 'グラデ薄桃黄緑', omikuji: "頼もしく" },
    { id: 21, order: 21, file_name: 'grd-03.png', title: 'グラデ黄色水色', omikuji: "頼もしく" },
    { id: 22, order: 22, file_name: 'grd-04.png', title: 'グラデ濃いピンク青', omikuji: "頼もしく" },
    { id: 23, order: 23, file_name: 'grd-05.png', title: 'グラデ赤青緑', omikuji: "頼もしく" },
    { id: 24, order: 24, file_name: 'grd-06.png', title: 'グラデ橙緑', omikuji: "頼もしく" },
    { id: 25, order: 25, file_name: 'bc-autumn-01.png', title: '夜空', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 26, order: 26, file_name: 'bc-autumn-02.png', title: 'オレンジ', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 27, order: 27, file_name: 'bc-autumn-03.png', title: '紫', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 28, order: 28, file_name: 'bc-autumn-04.png', title: 'グレージュ', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 29, order: 29, file_name: 'bc-autumn-05.png', title: 'くすみブルー', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 30, order: 30, file_name: 'bc-autumn-06.png', title: 'くすみピンク', omikuji: "頼もしい", event_name: 'AutumnEvent' },
    { id: 31, order: 31, file_name: 'bc-otsukimi-01.png', title: '月夜①', omikuji: "頼もしい", event_name: 'OtsukimiEvent' },
    { id: 32, order: 32, file_name: 'bc-otsukimi-02.png', title: '月夜②', omikuji: "頼もしい", event_name: 'OtsukimiEvent' },
    { id: 33, order: 33, file_name: 'bc-otsukimi-03.png', title: '月夜とススキ①', omikuji: "頼もしい", event_name: 'OtsukimiEvent' },
    { id: 34, order: 34, file_name: 'bc-otsukimi-04.png', title: '月夜とススキ②', omikuji: "頼もしい", event_name: 'OtsukimiEvent' },
    { id: 35, order: 35, file_name: 'summer-bk-01.png', title: '夏空と海①', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 36, order: 36, file_name: 'summer-bk-02.png', title: '夏空と海②', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 37, order: 37, file_name: 'summer-bk-03.png', title: '花火', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 38, order: 38, file_name: 'summer-bk-04.png', title: 'スイカ', omikuji: "頼もしい", event_name: 'SummerEvent' },
    { id: 39, order: 39, file_name: 'bc-xmas-01.png', title: 'みどり', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 40, order: 40, file_name: 'bc-xmas-02.png', title: 'ベージュ', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 41, order: 41, file_name: 'bc-xmas-03.png', title: '濃いベージュ', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 42, order: 42, file_name: 'bc-xmas-04.png', title: 'やさしい赤', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 43, order: 43, file_name: 'bc-xmas-05.png', title: '深い赤', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 44, order: 44, file_name: 'bc-xmas-06.png', title: '紺', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 45, order: 45, file_name: 'bc-xmas-07.png', title: 'ピンク', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 46, order: 46, file_name: 'bc-xmas-08.png', title: '黄金', omikuji: "頼もしい", event_name: 'XmasEvent' },
    { id: 47, order: 47, file_name: 'bc-xmas-09.png', title: '水浅葱', omikuji: "頼もしい", event_name: 'XmasEvent' },
];

const OmikujiDaishisData = [
    { id: 1, order: 1, file_name: 'omikuji-daishi-01.png', title: '赤' },
    { id: 2, order: 2, file_name: 'omikuji-daishi-02.png', title: '橙' },
    { id: 3, order: 3, file_name: 'omikuji-daishi-03.png', title: '緑' },
    { id: 4, order: 4, file_name: 'omikuji-daishi-04.png', title: '青' },
    { id: 5, order: 5, file_name: 'omikuji-daishi-05.png', title: 'グラデ黄ピンク' },
    { id: 6, order: 6, file_name: 'omikuji-daishi-06.png', title: 'グラデ緑青' },
    { id: 7, order: 7, file_name: 'omikuji-daishi-otsukimi-01.png', title: 'お月見', event_name: 'OtsukimiEvent' },
    { id: 8, order: 8, file_name: 'omikuji-daishi-autumn-01.png', title: '秋茶', event_name: 'AutumnEvent' },
    { id: 9, order: 9, file_name: 'omikuji-daishi-autumn-02.png', title: '秋赤', event_name: 'AutumnEvent' },
    { id: 10, order: 10, file_name: 'omikuji-daishi-autumn-03.png', title: '秋紫', event_name: 'AutumnEvent' },
    { id: 11, order: 11, file_name: 'omikuji-daishi-xmas-01.png', title: 'みどり', event_name: 'XmasEvent' },
    { id: 12, order: 12, file_name: 'omikuji-daishi-xmas-02.png', title: '紺', event_name: 'XmasEvent' },
    { id: 13, order: 13, file_name: 'omikuji-daishi-xmas-03.png', title: '深い赤', event_name: 'XmasEvent' },
    { id: 14, order: 14, file_name: 'omikuji-daishi-winter-01.png', title: '水色', event_name: 'WinterEvent' },
    { id: 15, order: 15, file_name: 'omikuji-daishi-winter-02.png', title: 'いい青', event_name: 'WinterEvent' },
    { id: 16, order: 16, file_name: 'omikuji-daishi-winter-03.png', title: '薄い青グレー', event_name: 'WinterEvent' },
];

export { PatternCategories, PatternImagesData, BaseImagesData, BackGroundImagesData, OmikujiDaishisData };