<template>
<section class="section-omikuji relative mb-12">
  <div class="flex flex-col items-center">
    <div class="relative w-full flex justify-center items-center">
      <!-- 生成された画像を表示 -->
      <div v-if="generatedImage" class="relative w-full h-full flex justify-center items-center">
        <img :src="generatedImage" alt="おみくじ画像" class="responsive-img slide-animation">
      </div>
      <div v-else class="relative w-full h-full flex justify-center items-center">
        <img src="/images/taitsu-maker/omikuji/omikuji-daishi.png" alt="おみくじ画像" class="responsive-img">
      </div>
    </div>
    <transition name="box-fade">
      <div v-if="showBox" class="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50">
        <transition name="box-shake">
          <div v-if="showBox" class="relative flex flex-col items-center">
            <div class="w-64 h-64 bg-cover bg-center" style="background-image: url('/images/taitsu-maker/omikuji/okapi-warking.gif');"></div>
            <div class="text-center">
              <p class="text-white text-lg font-bold bg-black bg-opacity-50 p-2 rounded">どんなタイツに出会えるかな？</p>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <div class="mt-8">
      <button @click="drawOmikuji" :class="{'btn-omikuji': !generatedImage, 'btn-no-animation': generatedImage}" class="btn-omikuji bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded">
        おみくじを引く
      </button>
    </div>
  </div>
</section>
</template>

<script>
import { db } from "../../../../firebase"; // firebase.jsからインポート
import { doc, getDoc, addDoc, collection, getDocs } from "firebase/firestore";
import { PatternImagesData, BaseImagesData, BackGroundImagesData, OmikujiDaishisData } from '@/data/TaitsuMaker/ImagesData';
import { OmikujiSentences } from '@/data/TaitsuMaker/Sentences';
import { default as eventsData } from '@/data/TaitsuMaker/imageListsData';

export default {
  name: 'OmikujiImage',
  props: {
    updateOmikujiImage: Function,
    updateIconImage: Function,
    updateShareMessage: Function
  },
  data() {
    return {
      daishiImage: 'omikuji-daishi-01.png',
      backgroundImage: 'bc01.jpg',
      bcPatternImage: 'bc-torii-white.png',
      baseImage: 'base02.png',
      patternImage: 'pt01.png',
      omikujiMessage: '',
      omikujiColor: '',
      omikujiDescription: '',
      omikujiShareMessage: '',
      showBox: false,
      showResult: false,
      showConfetti: false,
      generatedImage: null,
      addSuccessMessage: '',
      addErrorMessage: '',
      omikujiSentences: [],
    }
  },
  async created() {
    await this.loadOmikujiSentences();
  },
  computed: {
    imageLists() {
      const lists = {
        decoration: [],
        pattern: [],
        basePattern: [],
        base: [],
        bcPattern: [],
        background: [],
        omikujiDaishi: [],
        decorations: {}
      };
      eventsData.forEach(event => {
        if (event.isActive() || event.eventName === 'Regular') {
          Object.keys(lists).forEach(key => {
            const imageCategoryKey = key + 'Images';
            if (event[imageCategoryKey]) { // 現在のイベントで対応する画像カテゴリが存在するか確認
              event[imageCategoryKey].forEach(image => {
                // 同じ画像がすでにリストに追加されていないか確認
                if (!lists[key].some(item => item.name === image)) {
                  // 画像リストに画像オブジェクトを追加
                  const imageData = PatternImagesData.find(img => img.file_name === image);
                  lists[key].push({
                    name: image,
                    title: imageData ? imageData.title : '',
                    category_id: imageData ? imageData.category_id : null,
                    order: imageData ? imageData.order : null,
                    decoration: event.getDecoration()
                  });
                }
              });
            }
          });
        }
      });

      // orderに従って並び替え
      Object.keys(lists).forEach(key => {
        if (Array.isArray(lists[key])) {
          lists[key].sort((a, b) => a.order - b.order);
        }
      });

      return lists;
    },
  },
  methods: {
    async loadOmikujiSentences() {
      const cachedData = JSON.parse(localStorage.getItem('omikujiSentences'));

      // Firestoreのメタデータを取得
      const metadataRef = doc(db, "omikuji_metadata", "omikuji_sentences");
      const metadataSnap = await getDoc(metadataRef);

      if (metadataSnap.exists()) {
        const firestoreVersion = metadataSnap.data().version;

        // キャッシュが存在し、バージョンが一致する場合
        if (cachedData && cachedData.version === firestoreVersion) {
          this.omikujiSentences = cachedData.data;
          console.log("キャッシュからメッセージを読み込みました。");
          return;
        }
      }

      // Firestoreから最新データを取得
      try {
        const querySnapshot = await getDocs(collection(db, "omikuji_sentences"));
        this. omikujiSentences = querySnapshot.docs.map((doc) => doc.data().message);

        // キャッシュを更新
        const newCache = {
          data: this.omikujiSentences,
          version: metadataSnap.data().version,
          timestamp: Date.now(), // ローカルキャッシュの保存日時
        };
        localStorage.setItem('omikujiSentences', JSON.stringify(newCache));
        console.log("Firestoreからメッセージを取得し、キャッシュに保存しました");
      } catch(error) {
        console.error("メッセージの取得に失敗しました。: ", error);
      }
    },
    drawOmikuji() {
      // GA4でのイベントトラッキング
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'omikuji', {
          'event_category': 'button',
          'event_label': 'downloadOmikujiImg',
          'value': 1
        });
      }

      this.showBox = true;
      this.showResult = false;
      this.showConfetti = false;

      setTimeout(() => {
        if (typeof window !== 'undefined') { // クライアントサイドでのみ実行
          let randomBackground;
          // アクティブイベントも含めたデータからファイル名を決定し、参照する
          const randomBaseFileName = this.imageLists['base'][Math.floor(Math.random() * this.imageLists['base'].length)]['name'];
          const randomBase = BaseImagesData.find(base => base.file_name === randomBaseFileName);
          const randomPatternFileName = this.imageLists['pattern'][Math.floor(Math.random() * this.imageLists['pattern'].length)]['name'];
          const randomPattern = PatternImagesData.find(pattern => pattern.file_name === randomPatternFileName);
          const randomOmikujiDaishiFileName = this.imageLists['omikujiDaishi'][Math.floor(Math.random() * this.imageLists['omikujiDaishi'].length)]['name'];

          const randomOmikujiDaishi = OmikujiDaishisData.find(omikujiDaishi => omikujiDaishi.file_name === randomOmikujiDaishiFileName);
          const randomOmikujiDiscription = this.omikujiSentences[Math.floor(Math.random() * this.omikujiSentences.length)];
          do {
            const randomBackgroundFileName = this.imageLists['background'][Math.floor(Math.random() * this.imageLists['background'].length)]['name'];
            randomBackground = BackGroundImagesData.find(background => background.file_name === randomBackgroundFileName);
          } while (!randomBackground || randomBackground.title === randomBase.title);
          
          this.backgroundImage = randomBackground.file_name;
          this.baseImage = randomBase.file_name;
          this.patternImage = randomPattern.file_name;
          this.daishiImage = randomOmikujiDaishi.file_name;
          
          this.omikujiMessage = `${randomPattern.title}タイツ`;
          this.omikujiColor = `ラッキータイツカラー：${randomBase.title}`;
          this.omikujiDescription = randomOmikujiDiscription;
          this.omikujiShareMessage = `今日のタイツは${randomPattern.title}の${randomBase.title}タイツです✨`;

          this.generateOmikuji();

          this.showBox = false;
          this.showResult = true;
          this.showConfetti = true;

          // 選ばれた画像の情報を親コンポーネントに渡す
          this.updateIconImage({
            background: this.backgroundImage,
            base: this.baseImage,
            pattern: this.patternImage
          });
          // シェアメッセージを親コンポーネントに渡す
          this.updateShareMessage(this.omikujiShareMessage);
        }
      }, 2000); // 2秒後に結果を表示
    },
    generateOmikuji() {
      // おみくじ画像初期化
      this.generatedImage = null;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // 台紙画像を大きくするための設定
      const canvasWidth = 1080;
      const canvasHeight = 1920;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const daishiImg = new Image();
      const baseImg = new Image();
      const patternImg = new Image();
      const bcPatternImg = new Image();
      const backgroundImg = new Image();

      daishiImg.src = `/images/taitsu-maker/omikuji/${this.daishiImage}`;
      baseImg.src = `/images/taitsu-maker/base/${this.baseImage}`;
      patternImg.src = `/images/taitsu-maker/pattern/${this.patternImage}`;
      bcPatternImg.src = `/images/taitsu-maker/bcPattern/${this.bcPatternImage}`;
      backgroundImg.src = `/images/taitsu-maker/background/${this.backgroundImage}`;

      let imagesLoaded = 0;
      const totalImages = 4;

      const checkAllImagesLoaded = () => {
        imagesLoaded++;
        if (imagesLoaded === totalImages) {
          console.log('All images loaded');
          context.drawImage(daishiImg, 0, 0, canvasWidth, canvasHeight);

          const smallWidth = 800;
          const smallHeight = 800;
          const centerX = (canvasWidth - smallWidth) / 2;
          const centerY = (canvasHeight - smallHeight) / 2;
          context.drawImage(backgroundImg, centerX, centerY, smallWidth, smallHeight);
          // context.drawImage(bcPatternImg, centerX, centerY, smallWidth, smallHeight);
          context.drawImage(baseImg, centerX, centerY, smallWidth, smallHeight);
          context.drawImage(patternImg, centerX, centerY, smallWidth, smallHeight);


          // テキストを改行して描画する関数
          const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
            const words = text.split('');
            let line = '';
            let testLine = '';
            let testWidth = 0;
            for (let n = 0; n < words.length; n++) {
              testLine += words[n];
              testWidth = context.measureText(testLine).width;
              if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n];
                y += lineHeight;
                testLine = words[n];
              } else {
                line = testLine;
              }
            }
            context.fillText(line, x, y);
          };

          // おみくじタイトル
          const omikujiTitle = `${this.omikujiMessage}`;
          context.font = 'bold 100px "Zen Maru Gothic"';
          context.fillStyle = 'black';
          context.textAlign = 'center';
          wrapText(context, omikujiTitle, canvasWidth / 2, centerY - smallHeight / 2 + 150, 800, 110);

          // おみくじラッキータイツカラー
          const omikujiColor = `${this.omikujiColor}`;
          context.font = 'bold 48px "Zen Maru Gothic"';
          context.fillStyle = 'black';
          context.textAlign = 'center';
          wrapText(context, omikujiColor, canvasWidth / 2, centerY - smallHeight / 2 + 380, 720, 58);

          // おみくじメッセージ
          const omikujiDescription = `${this.omikujiDescription}`;
          context.font = '42px "Zen Maru Gothic"';
          context.fillStyle = 'black';
          context.textAlign = 'center';
          wrapText(context, omikujiDescription, canvasWidth / 2, centerY + smallHeight / 2 + 520, 720, 72);

          // 生成された画像をデータURLとして取得
          this.generatedImage = canvas.toDataURL('image/png');
          // console.log('Image generated', this.generatedImage);
          this.updateOmikujiImage(this.generatedImage); // 生成された画像URLを親コンポーネントに渡す
        }
      };

      daishiImg.onload = () => {
        console.log('daishiImg loaded');
        checkAllImagesLoaded();
      };

      backgroundImg.onload = () => {
        console.log('backgroundImg loaded');
        checkAllImagesLoaded();
      };

      baseImg.onload = () => {
        console.log('baseImg loaded');
        checkAllImagesLoaded();
      };

      patternImg.onload = () => {
        console.log('patternImg loaded');
        checkAllImagesLoaded();
      };

      daishiImg.onerror = (error) => {
        console.error('Error loading daishiImg', error);
      };

      backgroundImg.onerror = (error) => {
        console.error('Error loading backgroundImg', error);
      };

      baseImg.onerror = (error) => {
        console.error('Error loading baseImg', error);
      };

      patternImg.onerror = (error) => {
        console.error('Error loading patternImg', error);
      };
    },
    async addSentencesToFirestore() {
      try {
        const collectionRef = collection(db, "omikuji_sentences");
        for (const message of OmikujiSentences) {
          await addDoc(collectionRef, { message });
        }
        this.addSuccessMessage = "メッセージがFirestoreに追加されました！";
        this.addErrorMessage = ''; 
      } catch (error) {
        console.error("エラーが発生しましたぞ。: ", error);
        this.addErrorMessage = "エラーが発生しましたぞ。";
        this.addSuccessMessage = ''; 
      }
    }
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  .section-omikuji {
    margin-top: 20px;
  }
}

.responsive-img {
  width: 100%;
  height: auto;
  object-fit: contain; /* 縦横比を維持する */
  max-height: none; /* 初期値は無制限 */
}

@media (min-width: 768px) {
  .responsive-img {
    max-height: 80vh; /* タブレット以上の画面幅の時に最大高さを設定 */
    width: auto;
  }
}

.slide-animation {
  clip-path: inset(0 0 100% 0);
  animation: slideDown 1.5s ease-in-out forwards;
}

@keyframes slideDown {
  0% {
    clip-path: inset(0 0 100% 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

.circle-animation {
  clip-path: circle(0% at 50% 50%);
  animation: reveal 1.5s ease-in-out forwards;
}

@keyframes reveal {
  0% {
    clip-path: circle(0% at 50% 50%);
  }
  100% {
    clip-path: circle(100% at 50% 50%);
  }
}

.box-fade-enter-active, .box-fade-leave-active {
  transition: opacity 0.5s;
}
.box-fade-enter, .box-fade-leave-to {
  opacity: 0;
}

.box-shake-enter-active {
  animation: shake 0.5s;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.result-fade-enter-active, .result-fade-leave-active {
  transition: opacity 1s;
}
.result-fade-enter, .result-fade-leave-to {
  opacity: 0;
}

.confetti-fade-enter-active, .confetti-fade-leave-active {
  transition: opacity 1s;
}
.confetti-fade-enter, .confetti-fade-leave-to {
  opacity: 0;
}

.btn-omikuji {
  animation: btn_animation 2.0s infinite;
}

.btn-no-animation {
  animation: none;
}

@keyframes btn_animation {
    0% {
        transform: scale(1.2)
    }
    10% {
        transform: scale(1)
    }
    90% {
        transform: scale(1)
    }
    100% {
        -webkit-transform: scale(1.2)
    }
}
</style>