<template>
  <div class="flex flex-col justify-center items-center space-y-4 p-4 mb-12">
    <h2 class="text-xl font-bold text-gray-800">おみくじに込めた想い</h2>
    <p class="text-gray-600 text-sm">
      作者のたやまが<a class="underline text-pink-500" href="https://note.com/yuuritayama/n/n4db40a611074" target="blank">note</a>でおみくじのメッセージに込めた想いを語りました。<br />
    
    </p>
  </div>
</template>

<script>
export default {
  name: 'Omoi',
}
</script>
