<template>
  <div class="mb-16">
    <div class="flex justify-center mb-4 border-b">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        @click="setActiveTab(tab.id)"
        :class="{
          'font-semibold': activeTab === tab.id,
          'border-b-2 border-transparent': true,
        }"
        class="cursor-pointer py-2 px-4 text-xs relative"
      >
        {{ tab.label }}
        <span
          v-if="activeTab === tab.id"
          class="absolute bottom-0 left-0 right-0 mx-auto w-3/4 h-1 bg-gray-500 rounded-full"
        ></span>
      </div>
    </div>

    <!-- カテゴリ選択 -->
    <div v-if="activeTab === 'pattern'" class="flex justify-center mb-4">
      <div
        v-for="category in PatternCategories"
        :key="category.id"
        @click="activeCategory = category.id"
        :class="{
          'font-semibold': activeCategory === category.id,
          'border-b-2 border-transparent': true,
        }"
        class="cursor-pointer py-2 px-4 text-xs relative"
      >
        {{ category.name }}
        <span
          v-if="activeCategory === category.id"
          class="absolute bottom-0 left-0 right-0 mx-auto w-3/4 h-1 bg-gray-500 rounded-full"
        ></span>
      </div>
    </div>


    <!-- 各タブに対応する画像リストを動的に表示 -->
    <div v-if="activeTab && filteredImageLists.length > 0" class="mb-12 dynamic-margin">
      <swiper :modules="modules" :slides-per-view="slidesPerView" :scrollbar="{ draggable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="item in filteredImageLists" :key="item.name">
          <div v-if="item.decoration" :class="item.decoration.class" class="bg-opacity-30 text-center text-xs">
            {{ item.decoration.label }}
          </div>
          <img :src="getImageUrl(activeTab, item.name)" @click="selectImage(activeTab, item.name)" class="w-20 h-20">
          <p class="mb-4 text-xs w-20">{{ item.title }}</p>
        </swiper-slide>
      </swiper>
    </div>
    <div v-else class="text-center text-xs">
      現在アイテムはありません
    </div>
    
  </div>
</template>

<script>
  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper Vue.js components
/* eslint-disable */
import { Swiper, SwiperSlide } from 'swiper/vue';// eslint-disable-line no-unused-vars
/* eslint-enable */

// Import Swiper styles
import 'swiper/css';

import { PatternCategories, PatternImagesData } from '@/data/TaitsuMaker/ImagesData';

export default {
  name: 'ImageSelector',
  props: {
    events: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      // ファイル名のみを配列で保持
      decorationImages: [],
      patternImages: [],
      basePatternImages: [],
      baseImages: [],
      bcPatternImages: [],
      backgroundImages: [],
      selectedImages: {
        pattern: '',
        basePattern: '',
        base: '',
        bcPattern: '',
        background: '',
      },
      randomLimitedHeading: '',
      tabs: [
        { id: 'decoration', label: '飾り①' },
        { id: 'pattern', label: '動物' },
        { id: 'basePattern', label: '飾り②' },
        { id: 'base', label: 'タイツ' },
        { id: 'bcPattern', label: '背景模様' },
        { id: 'background', label: '背景' },
      ],
      activeTab: 'pattern',
      activeCategory: null,
      PatternCategories,
    };
  },
  computed: {
    imageLists() {
      const lists = {
        decoration: [],
        pattern: [],
        basePattern: [],
        base: [],
        bcPattern: [],
        background: [],
        decorations: {}
      };
      this.events.forEach(event => {
        if (event.isActive() || event.eventName === 'Regular') {
          Object.keys(lists).forEach(key => {
            const imageCategoryKey = key + 'Images';
            if (event[imageCategoryKey]) { // 現在のイベントで対応する画像カテゴリが存在するか確認
              event[imageCategoryKey].forEach(image => {
                // 同じ画像がすでにリストに追加されていないか確認
                if (!lists[key].some(item => item.name === image)) {
                  // 画像リストに画像オブジェクトを追加
                  const imageData = PatternImagesData.find(img => img.file_name === image);
                  lists[key].push({
                    name: image,
                    title: imageData ? imageData.title : '',
                    category_id: imageData ? imageData.category_id : null,
                    order: imageData ? imageData.order : null,
                    decoration: event.getDecoration()
                  });
                }
              });
            }
          });
        }
      });

      // orderに従って並び替え
      Object.keys(lists).forEach(key => {
        if (Array.isArray(lists[key])) {
          lists[key].sort((a, b) => a.order - b.order);
        }
      });

      return lists;
    },
    filteredImageLists() {
      if (this.activeTab === 'pattern' && this.activeCategory) {
        return this.imageLists[this.activeTab].filter(item => item.category_id === this.activeCategory);
      }
      return this.imageLists[this.activeTab];
    },
    slidesPerView() {
      if (this.windowWidth >= 1024) return 8;
      if (this.windowWidth >= 768) return 6;
      if (this.windowWidth >= 360) return 4;
      return 3;
    },
  },
  methods: {
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    setActiveTab(tabId) {
      this.activeTab = tabId;
      if (tabId !== 'pattern') {
        this.activeCategory = null;
      }
    },
    selectImage(type, image) {
      const notRequiredTypes = ['decoration', 'basePattern', 'bcPattern'];
      // 期間限定：同じ画像が再度クリックされた場合は選択を解除
      if (notRequiredTypes.includes(type) && this.selectedImages[type] === image) {
        this.selectedImages[type] = '';
        this.$emit('deselected', type);
      } else {
        this.selectedImages[type] = image;
        this.$emit('selected', type, image);
      }
    },
    getImageUrl(type, imageName) {
      // return `${process.env.BASE_URL}images/taitsu-maker/${type}/${imageName}`;
      // ファイル名から拡張子を取り除き、.jpgを追加
      const baseName = imageName.replace(/\.[^/.]+$/, ""); // 拡張子を削除
      return `${process.env.BASE_URL}images/taitsu-maker/thumbnail/${type}/${baseName}.jpg`;
    },
  },
  mounted() {
    // 初期設定
    this.selectImage('decoration', '');
    this.selectImage('pattern', 'pt01.png');
    this.selectImage('base', 'base02.png');
    this.selectImage('background', "bc01.jpg");
    this.selectImage('limitedBase', '');
    this.selectImage('limitedBc', '');
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
}
</script>

<style scoped>
/* Default styles */
.dynamic-margin {
  margin-left: 10px;
  margin-right: 10px;
}

/* For larger screens */
@media (min-width: 1024px) {
  .dynamic-margin {
    margin-left: 30px;
    margin-right: 30px;
  }
}
</style>